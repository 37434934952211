<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-menu-button></ion-menu-button>
                </ion-buttons>
                <ion-title>Cards</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content>
            <!-- Default Segment -->
            <ion-segment @ionChange="segmentChanged($event)">
                <ion-segment-button value="friends">
                    <ion-label>Friends</ion-label>
                </ion-segment-button>
                <ion-segment-button value="enemies">
                    <ion-label>Enemies</ion-label>
                </ion-segment-button>
            </ion-segment>

            <ion-grid>
                <ion-row>
                    <ion-col size="12" size-sm="6" size-xl="3">
                        <ion-card class="ios hydrated">
                            <img
                                src="https://ionicframework.com/docs/demos/api/card/madison.jpg"
                            />
                            <ion-card-header
                                class="ion-inherit-color ios hydrated"
                            >
                                <ion-card-subtitle
                                    role="heading"
                                    aria-level="3"
                                    class="ion-inherit-color ios hydrated"
                                    >Commission</ion-card-subtitle
                                >
                                <ion-card-title
                                    role="heading"
                                    aria-level="2"
                                    class="ion-inherit-color ios hydrated"
                                    >Current PV</ion-card-title
                                >
                            </ion-card-header>
                            <ion-card-content
                                class="ios card-content-ios hydrated"
                            >
                                Founded in 1829 on an isthmus between Lake
                                Monona and Lake Mendota, Madison was named the
                                capital of the Wisconsin Territory in 1836.
                            </ion-card-content>
                        </ion-card>
                    </ion-col>
                    <ion-col size="12" size-sm="6" size-xl="3">
                        <ion-card>
                            <img
                                src="https://ionicframework.com/docs/demos/api/card/madison.jpg"
                            />

                            <ion-card-header
                                class="ion-inherit-color ios hydrated"
                            >
<!--                                 <ion-card-subtitle
                                    role="heading"
                                    aria-level="3"
                                    class="ion-inherit-color ios hydrated"
                                    >Commission</ion-card-subtitle
                                >
                                <ion-card-title
                                    role="heading"
                                    aria-level="2"
                                    class="ion-inherit-color ios hydrated"
                                    >Current PV</ion-card-title
                                > -->
                                <ion-item lines="none">
                                    <ion-icon
                                        :icon="rocket"
                                        slot="start"
                                    ></ion-icon>
                                    <ion-card-title
                                        role="heading"
                                        aria-level="2"
                                        class="ion-inherit-color ios hydrated"
                                        >
                                            Current PV
                                        </ion-card-title>
                                </ion-item>
                            </ion-card-header>

                            <ion-card-content>
                                <ion-text>
                                    Add <strong>1</strong> new member in <strong>10</strong> days
                                </ion-text>
                            </ion-card-content>
                            <ion-item>
                                <ion-button fill="clear" slot="start"
                                    >See Details</ion-button
                                >
                                <ion-button fill="clear" slot="end" style="margin-left: 0px;"
                                    >Add Member</ion-button
                                >
                            </ion-item>
                        </ion-card>
                    </ion-col>


                </ion-row>
            </ion-grid>

            <ion-grid>
                <ion-row>
                    <ion-col size="12" size-sm="6" size-xl="3">
                        <!-- from ionic starter app enappd -->
                        <ion-card class="eventcard ion-margin-bottom">
                            <ion-img
                                src="https://ionicframework.com/docs/demos/api/card/madison.jpg"
                            ></ion-img>
                            <ion-card-content>
                                <ion-row>
                                    <ion-col size="2" class="ion-text-center"
                                        ><h2 class="month">DEC</h2>
                                        <h1 class="date">24</h1>
                                        <h3 class="day">Mon</h3></ion-col
                                    >
                                    <div class="separator"></div>
                                    <ion-col size="7" class="name"
                                        ><h2><strong>Event Name</strong></h2>
                                        <h4 class="venue">
                                            Event Venues
                                        </h4></ion-col
                                    >
                                    <ion-col size="2">
                                        <ion-button size="small" color="healypink">Small</ion-button>
                                    </ion-col>
                                </ion-row>
                                <div class="hor-separator"></div>
                                <ion-row>
                                    <ion-col size="6">
                                        <ion-badge color="healypink">
                                            Pink 
                                        </ion-badge>
                                        <ion-badge color="healydarkblue">
                                            Blue
                                        </ion-badge>
                                        <ion-badge color="healygreen">
                                            Green
                                        </ion-badge>
                                        <ion-badge color="healyturquoise">
                                            Turquoise
                                        </ion-badge>
                                    </ion-col>
                                    <ion-col size="6" class="righttext"
                                        >€ 100,00</ion-col
                                    >
                                </ion-row>
                            </ion-card-content>
                        </ion-card>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonImg,
    IonItem,
    IonLabel,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonText,
    IonTitle,
    IonToolbar,
} from "@ionic/vue";
import { pin, wine, warning, walk, rocket } from "ionicons/icons";

export default {
    name: "Folder",
    data: function () {
        return {};
    },
    components: {
        IonBadge,
        IonButton,
        IonButtons,
        IonCard,
        IonCardContent,
        IonCardHeader,
        IonCardSubtitle,
        IonCardTitle,
        IonCol,
        IonContent,
        IonGrid,
        IonHeader,
        IonIcon,
        IonImg,
        IonItem,
        IonLabel,
        IonMenuButton,
        IonPage,
        IonRow,
        IonSegment,
        IonSegmentButton,
        IonText,
        IonTitle,
        IonToolbar,
    },
    methods: {
        segmentChanged(ev) {
            // console.log("Segment changed", ev);
        },
    },
    setup() {
        return {
            pin,
            wine,
            warning,
            walk,
            rocket,
        };
    },
};
</script>

<style scoped>
#container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

#container strong {
    font-size: 20px;
    line-height: 26px;
}

#container p {
    font-size: 16px;
    line-height: 22px;
    color: #8c8c8c;
    margin: 0;
}

#container a {
    text-decoration: none;
}

ion-card-title {
    font-family: var(--ion-font-family-light);
    color: var(--ion-color-healydarkblue);
}

ion-icon {
    color: var(--ion-color-healydarkblue);
    margin-left: -16px;
}

ion-button {
    color: var(--ion-color-healypink);
}

ion-card {
    border-radius: 8px;
}

ion-card-content {
    color: var(--ion-color-healydarkblue);
}

/* classes from ionic 5 starter app ennapd */

/* .eventcard {
    color: var(--ion-color-dark);
} */

.month {
    color: var(--ion-color-healyturquoise);
}

.date {
    color: var(--ion-color-healygreen);
}

.day {
    color: var(--ion-color-healypink);
}

.venue {
    color: var(--ion-color-healyblue);
    margin-top: 6px;
}

.separator {
    height: 60px;
}

.name {
    padding-left: 10px !important;
}

.btn {
    border-radius: 5px;
    height: 38px !important;
    width: 80px;
}

.card-text {
    font-family: var(--ion-font-family);
    font-size: 20px;
    color: var(--ion-color-healy-darkblue);
}

</style>